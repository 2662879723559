import React, { Component } from "react";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout/index";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO/index";
import Section from "@narative/gatsby-theme-novela/src/components/Section/index";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings/index";
import GraphqlDashboard from "../components/GraphqlDashboard/GraphqlDashboard";

class Dashboard extends Component {

    render() {
        let description = "GraphQL Dashboard | Explore APIs";

        //http://localhost:8000/___graphql
        //https://serve.onegraph.com/dynamic?app_id=1875a225-786c-4372-a2da-4ec7856e61c8
        //https://countries.trevorblades.com

        let schemaUrl = "https://serve.onegraph.com/dynamic?app_id=1875a225-786c-4372-a2da-4ec7856e61c8";
        let defaultQueries = `# shift-option/alt-click on a query below to jump to it in the explorer
        # option/alt-click on a field in the explorer to select all subfields
        query npmPackage {
          npm {
            package(name: "onegraph-apollo-client") {
              name
              homepage
              downloads {
                lastMonth {
                  count
                }
              }
            }
          }
        }
        
        query graphQLPackage {
          npm {
            package(name: "graphql") {
              name
              homepage
              downloads {
                lastMonth {
                  count
                }
              }
            }
          }
        }
        
        fragment bundlephobiaInfo on BundlephobiaDependencyInfo {
          name
          size
          version
          history {
            dependencyCount
            size
            gzip
          }
        }`;

            return (
                <Layout>
                    <SEO description={description} title={description}>
                    </SEO>
                    <Section>
                        <div style={{marginTop: "50px"}}>
                            <Headings.h1>{description}</Headings.h1>
                            <br/>

                            <GraphqlDashboard schemaUrl={schemaUrl} defaultQueries={defaultQueries} />
                        </div>
                    </Section>
                </Layout>

            );
    }
}

export default Dashboard;